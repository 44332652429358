<template>
  <b-tooltip label="Reveal password" position="is-top" type="is-dark">
    <button
      :class="{ 'is-loading': password.state === 'processing' }"
      type="button"
      class="button"
      @click="encodeDecode"
    >
      <b-icon
        :icon="decoded && viewPassword ? 'eye-slash' : 'eye'"
        size="is-small"
      />
    </button>
  </b-tooltip>
</template>

<script>
export default {
  name: "EncodeIcon",
  props: {
    password: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewPassword: this.value
    };
  },
  computed: {
    decoded() {
      return this.password.state === "decoded";
    },
    passwordValue() {
      if (this.decoded) {
        return this.password.value;
      } else {
        const string = this.password.value.split(":")[1];
        if (!string) return this.password.value;
        const length = string.length / 2;
        return string.substring(0, length);
      }
    },
    formattedValue() {
      return this.viewPassword && this.decoded
        ? this.passwordValue
        : this.toBull(this.passwordValue);
    }
  },
  watch: {
    viewPassword(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    encodeDecode() {
      if (!this.viewPassword) {
        this.viewPassword = true;
        if (!this.decoded) this.$emit("decode");
      } else {
        this.viewPassword = false;
      }
    }
  }
};
</script>

<style lang="scss">
.text-no-transform {
  & > label {
    text-transform: none;
  }
}
</style>
